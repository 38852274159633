import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Ig from "../assets/img/instagramIcon.svg";
import Fb from "../assets/img/facebookIcon.svg";
import Li from "../assets/img/linkedinIcon.svg";
import Tt from "../assets/img/tiktokIcon.svg";

const FooterInner = styled.footer`
  background: #243A58;
  color: #fff;
  padding: 60px 0;

  .footerWrapper {
    @media (min-width: 900px) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      max-width: 1150px;
      margin: 0 auto;
      padding-top: 0;
      width: calc(100% - 50px);
    }
  }

  .footerWrapperBot {
    ul {
      &:nth-child(1) {
        @media (min-width: 900px) {
          order: 1;
          width: calc(100%/3 - 30px);
        }
      }

      &:nth-child(2) {
        @media (min-width: 900px) {
          order: 2;
          width: calc(100%/3 - 30px);
        }
      }

      &:nth-child(3) {
        @media (min-width: 900px) {
          order: 3;
          width: calc(100%/3 - 30px);
        }
      }
    }
  }

  .pobockyNadpis {
    width: calc(100% - 50px);
    margin: 0 auto;
    font-family: Visuelt-Bold, Helvetica;
    font-size: 18px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 22px;
  }

  ul {
    width: calc(100% - 80px);
    margin: 0 auto;
    padding-bottom: 50px;

    @media (min-width: 900px) {
      padding-bottom: 40px;
    }

    &:nth-child(1) {
      @media (min-width: 900px) {
        order: 1;
        width: 180px;
      }
    }

    &:nth-child(2) {
      @media (min-width: 900px) {
        order: 3;
        width: 180px;
      }
    }

    &:nth-child(3) {
      @media (min-width: 900px) {
        order: 2;
        width: 340px;
      }
    }

    li {
      font-family: Visuelt-Regular, Helvetica;
      font-size: 16px;
      color: #fff;
      text-align: center;
      list-style: none;
      margin-bottom: 5px;

      a {
        font-family: Visuelt-Regular, Helvetica;
        font-size: 16px;
        color: #fff;
        text-align: center;
        text-decoration: none;
      }

      h4 {
        font-family: Visuelt-Bold, Helvetica;
        font-size: 18px;
        color: #FFFFFF;
        text-align: center;
        margin-bottom: 22px;
      }
    }

    .footerPopisText {
      font-family: Visuelt-Regular, Helvetica;
      font-size: 16px;
      color: rgba(255,255,255,0.60);
      text-align: center;
      margin-top: 25px;
    }

    .socials {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin: 0 auto;
      margin-top: 25px;
      max-width: 250px;

      a {
        &:hover {
          img {
            opacity: 100%;
          }
        }

        img {
          opacity: 80%;
        }
      }
    }
  }

  .copyright {
    font-family: Visuelt-Medium, Helvetica;
    font-size: 14px;
    color: rgba(255,255,255,0.60);
    text-align: center;
    line-height: 20px;
    border-top: 1px solid rgba(255,255,255,0.20);
    width: calc(100% - 80px);
    margin: 0 auto;
    padding-top: 30px;
    max-width: 1150px;
  }
`;

const Footer = ({ children }) => {
  return (
    <FooterInner>
      <div className="footerWrapper">
        <ul>
          <li>
            <h4>Kontakt</h4>
          </li>
          <li>
            <a href="tel:00420315558136">+420 315 558 136</a>
          </li>
          <li>
            <a href="mailto:podpora@goodbye.cz">podpora@goodbye.cz</a>
          </li>
          <li className="footerPopisText">Jsme tu pro vás 24 hodin denně, 7 dní v týdnu.</li>
          <li className="socials">
            <a target="_blank" href="https://www.tiktok.com/@goodbye.cz">
              <img src={Tt} alt="TikTok ikona" />
            </a>
            <a target="_blank" href="https://www.instagram.com/goodbye.cz/">
              <img src={Ig} alt="Instagram ikona" />
            </a>
            <a target="_blank" href="https://www.facebook.com/czgoodbye/">
              <img src={Fb} alt="Facebook ikona" />
            </a>
            <a target="_blank" href="https://www.linkedin.com/company/goodbye-cz/">
              <img src={Li} alt="LinkedIn ikona" />
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <h4>Menu</h4>
          </li>
          <li>
            <Link to="/pohrebni-sluzba/">Pohřební služba</Link>
          </li>
          <li>
            <Link to="/kremace-bez-obradu/">Kremace bez obřadu</Link>
          </li>
          <li>
            <Link to="/pohreb-s-obradem/">Pohřeb s obřadem</Link>
          </li>
          <li>
            <Link to="/netradicni-pohreb/">Netradiční pohřeb</Link>
          </li>
          <li>
            <Link to="/pohreb-miminka/">Pohřeb miminka</Link>
          </li>
          <li>
            <Link to="/predplaceny-pohreb/">Předplacený pohřeb</Link>
          </li>
          <li>
            <Link to="/rakve/">Rakve na pohřeb</Link>
          </li>
          <li>
            <Link to="/seznam-hospicu/">Seznam hospiců</Link>
          </li>
          <li>
            <Link to="/pobocky/">Kde fungujeme?</Link>
          </li>
          <li>
            <Link to="/prihlasit-se/">Přihlásit se</Link>
          </li>
          <li>
            <Link to="/zaveti/">Závěti</Link>
          </li>
          <li>
            <Link to="/digitalni-archiv/">Digitální archiv</Link>
          </li>
          <li>
            <Link to="/care/">Goodbye Care</Link>
          </li>
          <li>
            <Link to="/parte/">Online parte</Link>
          </li>
          <li>
            <Link to="/poradna/">Poradna</Link>
          </li>
          <li>
            <Link to="/o-nas/">O nás</Link>
          </li>
          <li>
            <Link to="/slovnik-pojmu/">Slovník pojmů</Link>
          </li>
          <li>
            <Link to="/obradni-sine/">Obřadní síně</Link>
          </li>
          <li>
            <a href="https://bit.ly/3ecnK1v">Press kit</a>
          </li>
        </ul>
        <ul>
          <li>
            <h4>Goodbye.cz</h4>
          </li>
          <li>Goodbye s.r.o.</li>
          <li>Na Kopci 164, Svojetice 251 62</li>
          <li>IČO: 08319588</li>
          <li>DIČ: CZ08319588</li>
          <li>Kontaktní adresa: Tomkova 3, Praha 5, 150 00</li>
          <li className="footerPopisText">Zapsáno v obchodním rejstříku pod spisovou značkou C 316877 u Městského soudu v Praze.</li>
          <li className="footerPopisText">Držitel koncesovaného živnostenského oprávnění na provozování pohřební služby.</li>

          <li className="footerPopisText">
            <a className="footerPopisText" href="/provoz.pdf">Řád pro provozování pohřební služby</a>
          </li>

          <li className="footerPopisText">
            <Link className="footerPopisText" to="/obchodni-podminky/">Obchodní podmínky (pohřební služba)</Link>
            <br/>
            <Link className="footerPopisText" to="/zaveti/obchodni-podminky/">Obchodní podmínky (závěti)</Link>
          </li>

          <li className="footerPopisText">
            <a className="footerPopisText" href="/trendy-a-statistiky/">Zajímavé trendy a statistiky o smrti</a>
          </li>

          


        </ul>
      </div>
      <h4 className="pobockyNadpis">Naše regiony</h4>
      <div className="footerWrapper footerWrapperBot">
        <ul>
          <li>
            <Link to="/pohrebni-sluzba-praha/">Pohřební služba Praha</Link>
          </li>
          <li>
            <Link to="/pohrebni-sluzba-brno/">Pohřební služba Brno</Link>
          </li>
          <li>
            <Link to="/pohrebni-sluzba-ostrava/">Pohřební služba Ostrava</Link>
          </li>
          <li>
            <Link to="/pohrebni-sluzba-plzen/">Pohřební služba Plzeň</Link>
          </li>
          <li>
            <Link to="/pohrebni-sluzba-liberec/">Pohřební služba Liberec</Link>
          </li>
          <li>
            <Link to="/pohrebni-sluzba-ceske-budejovice/">Pohřební služba České Budějovice</Link>
          </li>
          <li>
            <Link to="/pohrebni-sluzba-usti-nad-labem/">Pohřební služba Ústí nad Labem</Link>
          </li>
          <li>
            <Link to="/pohrebni-sluzba-hradec-kralove/">Pohřební služba Hradec Králové</Link>
          </li>
          <li>
            <Link to="/pohrebni-sluzba-pardubice/">Pohřební služba Pardubice</Link>
          </li>
          <li>
            <Link to="/pohrebni-sluzba-havirov/">Pohřební služba Havířov</Link>
          </li>
        </ul>
        <ul>
          <li>
            <Link to="/pohrebni-sluzba-kladno/">Pohřební služba Kladno</Link>
          </li>
          <li>
            <Link to="/pohrebni-sluzba-most/">Pohřební služba Most</Link>
          </li>
          <li>
            <Link to="/pohrebni-sluzba-opava/">Pohřební služba Opava</Link>
          </li>
          <li>
            <Link to="/pohrebni-sluzba-jihlava/">Pohřební služba Jihlava</Link>
          </li>
          <li>
            <Link to="/pohrebni-sluzba-teplice/">Pohřební služba Teplice</Link>
          </li>
          <li>
            <Link to="/pohrebni-sluzba-decin/">Pohřební služba Děčín</Link>
          </li>
          <li>
            <Link to="/pohrebni-sluzba-chomutov/">Pohřební služba Chomutov</Link>
          </li>
          <li>
            <Link to="/pohrebni-sluzba-jablonec-nad-nisou/">Pohřební služba Jablonec nad Nisou</Link>
          </li>
          <li>
            <Link to="/pohrebni-sluzba-kolin/">Pohřební služba Kolín</Link>
          </li>
        </ul>
        <ul>
          <li>
            <Link to="/pohrebni-sluzba-pisek/">Pohřební služba Písek</Link>
          </li>
          <li>
            <Link to="/pohrebni-sluzba-uherske-hradiste/">Pohřební služba Uherské Hradiště</Link>
          </li>
          <li>
            <Link to="/pohrebni-sluzba-litomerice/">Pohřební služba Litoměřice</Link>
          </li>
          <li>
            <Link to="/pohrebni-sluzba-kutna-hora/">Pohřební služba Kutná Hora</Link>
          </li>
          <li>
            <Link to="/pohrebni-sluzba-ricany/">Pohřební služba Říčany</Link>
          </li>
          <li>
            <Link to="/pohrebni-sluzba-podebrady/">Pohřební služba Poděbrady</Link>
          </li>
          <li>
            <Link to="/pohrebni-sluzba-cesky-krumlov/">Pohřební služba Český Krumlov</Link>
          </li>
          <li>
            <Link to="/pohrebni-sluzba-caslav/">Pohřební služba Čáslav</Link>
          </li>
          <li>
            <Link to="/pohrebni-sluzba-sazava/">Pohřební služba Sázava</Link>
          </li>
        </ul>
      </div>
      <div className="copyright">Copyright © 2024</div>
      {children}
    </FooterInner>
  );
}

export default Footer;
